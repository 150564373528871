import AccordionDrawer from './accordion-drawer';
import EVENT from '../enums/event';

const selectors = {
  drawer: '[data-accordion-drawer]',
};

class Accordion {
  /**
   *
   * @param {Element} element
   */
  constructor(element) {
    this.accordion = element;

    if (!this.accordion) return;

    this.drawers = [...this.accordion.querySelectorAll(selectors.drawer)].map(
      drawer => new AccordionDrawer(drawer)
    );

    this.init();
  }

  init() {
    this.registerEventListeners();
  }

  open(index) {
    if (this.drawers[index]) {
      this.drawers[index].open();
    }
  }

  registerEventListeners() {
    this.closeDrawersEvents();
  }

  closeDrawersEvents() {
    this.accordion.addEventListener(EVENT.ACCORDION.DRAWERCLOSED, event => {
      this.drawers.forEach(drawer => {
        if (drawer !== event.detail.drawer) {
          if (drawer.button.getAttribute('aria-expanded') === 'true') {
            drawer.close();
          }
        }
      });
    });
  }
}

export default Accordion;
